import React from 'react';
import { Button } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLoading } from '../../ui-components/loadbar/loadingcontext';

interface Api {
  id: string;
}

const Reconcile: React.FC<Api> = ({id}) => {
  const { setLoading } = useLoading();
  // Füge die Funktion hinzu, die den API-Aufruf durchführt.
  const handleApiCall = async () => {
    const token = localStorage.getItem('authToken');
    setLoading(true); 

    if (!token) {
      console.error('Kein Token gefunden, bitte einloggen');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/hosts/${id}/reconcile/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        toast.success('API call was successful!');
      } else {
        toast.error('Fehler beim Senden der Daten:\n'  + result["status"]);
        console.error('Fehler beim Senden der Daten:' + result["status"]);
      }
    } catch (error) {
      toast.error('Fehler beim Abrufen der API-Daten:'+ error);
      console.error('Fehler beim Abrufen der API-Daten:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={handleApiCall} className="text-secondary">
      start reconciliation
    </Button>
  );
};

export default Reconcile;