import * as React from "react";
import { Routes, Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import ClusterList from "../clusters/clusterList"
import ClusterDetails from "../clusters/details/clusterDetails"
import OrganizationsList from "../organizations/oganizationsList"
import OrganizationsDetails from "../organizations/details/organizationDetails"
import EndpointsList from "../endpoints/list"
import EndpointItem from "../endpoints/details/view"
import WorkspacesList from "../workspaces/workspacesList"
import WorkspacesDetails from "../workspaces/details/workspacesDetails"
import WorkspaceImport from "../workspaces/workspaceImport"
import OrganizationsAdd from "../organizations/details/organizationAdd"
import HostsList from "../hosts/hostList"
import HostsDetails from "../hosts/details/hostDetails"
import AppsList from "../apps/appsList"
import AppDetails from "../apps/details/appDetails"
import BlocksList from "../blocks/list"
import BlockItem from "../blocks/item/view"
import Charts from "../ui-components/charts/prom"
import Browser from "../browser/browser"
import Login from "../login/login"
import NoMatch from "./nomatch"

import {
  IconButton,
  Typography,
  Collapse,
  Switch
} from "@material-tailwind/react";

import {
  City,
  Menu,
  GridMinus,
  Server,
  SelectFace3d,
  Xmark,
  DatabaseSearch,
  CubeScan,
  Cube,
  Axes
} from "iconoir-react";

const LINKS = [
  {
    icon: City,

    title: "Organizations",

    href: "/organizations/",
  },

  {
    icon: CubeScan,

    title: "Workspaces",

    href: "/workspaces/",
  },

  {
    icon: GridMinus,

    title: "Cluster",

    href: "/cluster/",
  },

  {
    icon: Server,

    title: "Hosts",

    href: "/hosts/",
  },

  {
    icon: Axes,

    title: "Endpoints",

    href: "/endpoints/",
  },

  {
    icon: SelectFace3d,

    title: "Apps",

    href: "/apps",
  },

  {
    icon: Cube,

    title: "Blocks",

    href: "/blocks",
  },

  {
    icon: DatabaseSearch,

    title: "API Browser",

    href: "/browser",
  },
];

function NavList() {
  return (
    <ul className="mt-4 flex flex-col gap-x-3 gap-y-1.5 lg:mt-0 lg:flex-row lg:items-center">
      {LINKS.map(({ icon: Icon, title, href }) => (
        <li key={title}>
          <Typography
            type="small"
            className="flex items-center text-foreground gap-x-2 p-1 hover:text-primary"
          >
            <Icon className="h-4 w-4" />

            <NavLink 
              to={href}
              className={({ isActive }) => {
                return isActive ? "text-primary" : "";
              }}
            >  
              {title}
            </NavLink>
          </Typography>
        </li>
      ))}
    </ul>
  );
}

export default function StickyNavbar() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",

      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  return (
    <div className="w-full h-svh">
      <nav className="border-b-primary shadow-lg z-[9000] overflow-hidden p-2 border-surface shadow-white/10 sticky backdrop-blur-sm bg-primary/10 top-0 mx-auto w-full border-b">
        <div className="flex items-center">
          <Typography
            as="a"
            href="#"
            type="small"
            className="ml-2 mr-2 w-[240px] block py-1 text-primary font-bold"
          >
            Ayedo Cloud
          </Typography>

          <hr className="mr-1.5 hidden h-5 w-px border-l border-t-0 border-primary lg:block" />

          <div className="hidden lg:block">
            <NavList />
          </div>

          <div className="flex rounded items-center gap-2 ml-auto">
            <Login className="hidden lg:ml-auto lg:inline-block"/>
          </div>

          <IconButton
            size="sm"
            variant="ghost"
            color="secondary"
            onClick={() => setOpenNav(!openNav)}
            className="ml-auto grid lg:hidden"
          >
            {openNav ? (
              <Xmark className="h-4 w-4" />
            ) : (
              <Menu className="h-4 w-4" />
            )}
          </IconButton>
        </div>

        <Collapse open={openNav}>
          <NavList />
          <Login className="w-full my-4"/>
        </Collapse>
      </nav>
      <main className="w-full text-secondary-light">
        <div className="mx-auto p-5">
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/organizations/" element={<OrganizationsList />} />
            <Route path="/organizations/:id" element={<OrganizationsDetails />} />
            <Route path="/endpoints/" element={<EndpointsList />} />
            <Route path="/endpoints/:id" element={<EndpointItem />} />
            <Route path="/organizations/add" element={<OrganizationsAdd />} />
            <Route path="/workspaces/" element={<WorkspacesList />} />
            <Route path="/workspaces/:id" element={<WorkspacesDetails />} />
            <Route path="/workspaces/import" element={<WorkspaceImport />} />
            <Route path="/cluster/" element={<ClusterList />} />
            <Route path="/cluster/:id" element={<ClusterDetails />} />
            <Route path="/hosts/" element={<HostsList />} />
            <Route path="/hosts/:id" element={<HostsDetails />} />
            <Route path="/apps/" element={<AppsList />} />
            <Route path="/apps/:id" element={<AppDetails />} />
            <Route path="/blocks/" element={<BlocksList />} />
            <Route path="/blocks/:id" element={<BlockItem />} />
            <Route path="/charts/" element={<Charts />} />
            <Route path="/browser/" element={<Browser />} />
            <Route path="*" element={<NoMatch />} />
         </Routes>
        </div>
      </main>
    </div>
  );
}
