import React, { useState, useEffect } from 'react';
import {  useParams } from "react-router-dom";
import JsonEditor from "../../ui-components/editor/monaco";
import Discover from "../actions/discover";
import Reconcile from "../actions/reconcile";
import {
  Card,
  Typography,
} from "@material-tailwind/react";

interface Row {
    id: string;
    hostname: string,
    state: string,
    provider: string,
    reconciliation_enabled: boolean;
    reconciliation_running: boolean;
    reconciliation_task_id: string;
    last_reconciliation: string;
    discovery_enabled: boolean;
    discovery_running: boolean;
    discovery_task_id: string;
    last_discovery: string;
    repair_running: boolean;
    repair_task_id: string;
    last_repair: string;
    kind: string;
    scope: string;
    labels: [];
}


export default function TransactionsTable() {

    const [data, setData] = useState<Row>({
      id: "",
      hostname: "",
      state: "",
      provider: "",
      reconciliation_enabled: false,
      reconciliation_running: false,
      reconciliation_task_id: "",
      last_reconciliation: "",
      discovery_enabled: false,
      discovery_running: false,
      discovery_task_id: "",
      last_discovery: "",
      repair_running: false,
      repair_task_id: "",
      last_repair: "",
      kind: "",
      scope: "",
      labels: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('authToken');

            if (!token) {
              console.error('Kein Token gefunden, bitte einloggen');
              return;
            }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/hosts/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
            });
            if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            setData(result);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while fetching the data. Please try again later.');
            setLoading(false);
        }
        };
    
        fetchData();
    }, []);

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6">Host</Typography>

          <Typography className="mt-1">
          {data.hostname} 
          </Typography>
        </div>
      </div>

      <div className="flex gap-4">
        <Discover id={data.id} />
        <Reconcile id={data.id} />
      </div>

      <div className="mt-6 border-t border-gray-600">
        <dl className="divide-y divide-gray-600">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">kind</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.kind.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">scope</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.scope.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">labels</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0"><pre>{JSON.stringify(data.labels, null,2)}</pre></dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.last_reconciliation}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.reconciliation_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.last_discovery}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.discovery_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">repair running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.repair_running.toString()} / {data.repair_task_id}</dd>
          </div>
        </dl>
      </div>

      <hr className="-mx-3 my-3 border-secondary" />

      <div className="w-full overflow-hidden rounded-lg">
          <Card className="m-1 space-y-4">
          <JsonEditor jsonData={data} />
          </Card>
      </div>
    </div>
  );
}
