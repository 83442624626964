import React from 'react';
import Editor from '@monaco-editor/react';

interface JsonEditorProps {
  jsonData: object;
}

const JsonEditor: React.FC<JsonEditorProps> = ({ jsonData }) => {
  return (
    <Editor
      height="80vh" // Höhe des Editors
      defaultLanguage="json"
      value={JSON.stringify(jsonData, null, 2)} // Formatierte JSON-Daten
      options={{
        readOnly: true, // ReadOnly-Modus aktivieren
        minimap: { enabled: false }, // Minimap deaktivieren (optional)
      }}
      theme="vs-dark"
    />
  );
};

export default JsonEditor;