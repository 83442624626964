import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
//import ClusterSyncFromGitlab from "../actions/clusterSyncFromGitlab";
import GetCredentials from "../actions/getCredentials";
import Discover from "../actions/discover";
import Reconcile from "../actions/reconcile";
import PromChart from "../../ui-components/charts/prom";
import JsonEditor from "../../ui-components/editor/monaco";
import { NavArrowDown, EyeSolid, EyeClosed } from "iconoir-react";
import Blob from '../../ui-components/blob/blob'
import {
  Card,
  Typography,
  Tabs,
  Accordion,
  Chip
} from "@material-tailwind/react";

interface Row {
    id: string,
    name: string,
    alias: string,
    state: string,
    provider: string,
    //hosts: Hosts[],
    k8s_cluster_nodes: K8sClusterNode[],
    credentials: Credentials[],
    //logs: Logs[],
    system_check: SystemCheck;
    conditions: Conditions[];
    reconciliation_enabled: boolean;
    reconciliation_running: boolean;
    reconciliation_task_id: string;
    last_reconciliation: string;
    discovery_enabled: boolean;
    discovery_running: boolean;
    discovery_task_id: string;
    last_discovery: string;
    repair_running: boolean;
    repair_task_id: string;
    last_repair: string;
    kind: string;
    scope: string;
    labels: [];
}

interface Conditions {
  type: string;
  reason: string;
  context: ConditionObjectType
}

interface ConditionObjectType {
  object_type: string;
}

interface K8sClusterNode {
  k8s_cluster: string;
  state: string;
  //host: Hosts;
  role: string;
  name: string;
  pod_cidr: string;
  pod_cidrs: string[];
  addresses: Address[];
  taints: Taints[]|null;
  allocatable: Resource;
  capacity: Resource;
  annotations: any;
  conditions: Condition[];
  images: Image[];
  node_info: NodeInfo;
  system_check: SystemCheck;
}

interface Taints {
  key: string;
  effect: string;
  timeAdded: string;
}

interface Address {
  type: string;
  address: string;
}

interface Resource {
  cpu: string;
  pods: string;
  memory: string;
  // hugepages-1Gi: string;
  // hugepages-2Mi: string;
  // ephemeral-storage: string;
}

// interface Annotations {
//   "k3s.io/node-env": string;
//   "k3s.io/node-args": string;
//   "k3s.io/node-config-hash": string;
//   "node.alpha.kubernetes.io/ttl": string;
//   "csi.volume.kubernetes.io/nodeid": string;
//   "alpha.kubernetes.io/provided-node-ip": string;
//   "volumes.kubernetes.io/controller-managed-attach-detach": string;
// }

interface Condition {
  type: string;
  reason: string;
  status: string;
  message: string;
  lastHeartbeatTime: string;
  lastTransitionTime: string;
}

interface Image {
  names: string[];
  sizeBytes: number;
}

interface NodeInfo {
  bootID: string;
  osImage: string;
  machineID: string;
  systemUUID: string;
  architecture: string;
  kernelVersion: string;
  kubeletVersion: string;
  operatingSystem: string;
  kubeProxyVersion: string;
  containerRuntimeVersion: string;
}

interface SystemCheck {
  k8s_cluster: string;
  state: string;
  last_state: string;
  provider: string;
  kubeconfig_ca_cert_expires_soon: boolean;
  kubeconfig_ca_cert_expired: boolean;
  kubeconfig_client_cert_expires_soon: boolean;
  kubeconfig_client_cert_expired: boolean;
  api_server_cert_expires_soon: boolean;
  api_server_cert_expired: boolean;
  api_server_unreachable: boolean;
  version_is_behind_stable: boolean;
  kubeconfig_ca_cert_expiry_date: string;
  kubeconfig_client_cert_expiry_date: string;
  api_server_cert_expiry_date: string;
  kubernetes_version: string
}

interface Credentials {
  id: string;
  name: string;
  active: boolean;
  kind: string;
  provider: string;
}

// interface Logs {
//   severity: string;
//   message: string;
//   created_at: string;
// }

// interface Hosts {
//   id: string;
//   hostname: string;
//   alias: string | null;
//   description: string | null;
//   active: boolean;
//   provider: string;
//   kind: string;
//   scope: string;
//   role: string;
//   url: string;
//   labels: string;
//   provider_id: string;
//   provider_status: string;
//   provider_datacenter: string;
//   provider_location: string;
//   provider_image: string;
//   provider_image_os_flavor: string;
//   provider_image_os_version: string;
//   provider_image_os_architecture: string;
//   provider_type: string;
//   default_ipv4: string;
//   default_ipv6: string;
//   resource_cpu_type: string;
//   resource_cpu_cores: number;
//   resource_architecture: string;
//   resource_memory: number;
//   resource_disk: number;
//   k8s_cluster: string;
//   organization: string;
// }

export default function TransactionsTable() {

    const [data, setData] = useState<Row>({
      id: "",
      name: "",
      alias: "",
      state: "",
      provider: "",
      //hosts: [],
      k8s_cluster_nodes: [],
      credentials: [],
      //logs: [],
      reconciliation_enabled: false,
      reconciliation_running: false,
      reconciliation_task_id: "",
      last_reconciliation: "",
      discovery_enabled: false,
      discovery_running: false,
      discovery_task_id: "",
      last_discovery: "",
      repair_running: false,
      repair_task_id: "",
      last_repair: "",
      kind: "",
      scope: "",
      labels: [],
      conditions: [],
      system_check: {
        k8s_cluster: "",
        state: "",
        last_state: "",
        provider: "",
        kubeconfig_ca_cert_expires_soon: false,
        kubeconfig_ca_cert_expired: false,
        kubeconfig_client_cert_expires_soon: false,
        kubeconfig_client_cert_expired: false,
        api_server_cert_expires_soon: false,
        api_server_cert_expired: false,
        api_server_unreachable: false,
        version_is_behind_stable: false,
        kubeconfig_ca_cert_expiry_date: "",
        kubeconfig_client_cert_expiry_date: "",
        api_server_cert_expiry_date: "",
        kubernetes_version: ""        
      }
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          const token = localStorage.getItem('authToken');

          if (!token) {
            console.error('Kein Token gefunden, bitte einloggen');
            return;
          }
          try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/kubernetes/clusters/${id}/`, {
              method: 'GET',
              headers: {
                  'Authorization': `Token ${token}`
              }
              });
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const result = await response.json();
              setData(result);
          } catch (error) {
              console.error('Error fetching data:', error);
              setError('An error occurred while fetching the data. Please try again later.');
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
        const intervalId = setInterval(fetchData, 5000);

        return () => clearInterval(intervalId);
    }, [setLoading]);


  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6" color="primary">Cluster</Typography>

          <Typography className="mt-1">
          {data.name} 
          </Typography>
        </div>
      </div>
      
      <hr className="-mx-3 my-3 border-secondary" />

      <div className="flex gap-4">
        <Discover cluster_id={data.id} />
        <Reconcile cluster_id={data.id} />
      </div>
   
      <hr className="-mx-3 my-3 border-secondary" />
      <div className="mt-6 border-t border-gray-600">
        <dl className="divide-y divide-gray-600">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">kind</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.kind.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">scope</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.scope.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">labels</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0"><pre>{JSON.stringify(data.labels, null,2)}</pre></dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.last_reconciliation}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.reconciliation_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.last_discovery}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.discovery_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">repair running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.repair_running.toString()} / {data.repair_task_id}</dd>
          </div>
        </dl>
      </div>

      <hr className="-mx-3 my-3 border-secondary" />
      <div>
 
      <Accordion>
      <Accordion.Item value="raw">
        <Accordion.Trigger>
          Raw
          <NavArrowDown className="h-4 w-4 data-[open=true]:rotate-180" />
        </Accordion.Trigger>
        <Accordion.Content>
        <Card className="m-1 space-y-4">
            <JsonEditor jsonData={data} />
            </Card>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
        <div className="w-full rounded-lg">

        </div>
      </div>

    </div>
  );
}
