import React, { useState, useEffect, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Input, Button, Select, Radio, Typography } from '@material-tailwind/react';
import { useLoading } from '../../ui-components/loadbar/loadingcontext';
import { Check, PlusCircle } from "iconoir-react";

interface Row {
    id: string,
    active: boolean,
    legal_name: string,
    description: string,
    alias: string,
    provider: string
}

const SearchableDropdown = ({setFormData}:any) => {
  const [data, setData] = useState<Row[]>([]);
  const [error, setError] = useState("");
  // const [loading, setLoading] = useState(true);
  const { setLoading } = useLoading();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        const token = localStorage.getItem('authToken');

        if (!token) {
          console.error('Kein Token gefunden, bitte einloggen');
          return;
        }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/organizations/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
        });
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result['results']);
    } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching the data. Please try again later.');
    } finally {
      setLoading(false);
    }
    };

    fetchData();
    // const intervalId = setInterval(fetchData, 10000); // Setze das Intervall auf 5000ms (5 Sekunden)

    // return () => clearInterval(intervalId); // Bereinige das Intervall beim Unmount
}, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedItem(event.target.value);
  // };
  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
    setFormData((prevData:any) => ({ ...prevData, organization: selectedValue }));
  };

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = data.filter(item =>
    item.legal_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="flex mb-2">
        <Input className="" id="test">
          <Input.Field
              placeholder="Filter"
              type="text"
              id="organization"
              value={searchTerm}
              onChange={handleSearch}
              className="mb-4"
          />
        </Input>
        <NavLink 
          to="/organizations/add"
          className="ml-2"
        >  
          <Button variant="solid">
          <PlusCircle className="me-2 h-4 w-4 stroke-2" />
            Create Organization
          </Button>
        </NavLink>
        </div>
        <Radio className="hover:transition-all duration-700 ease-in-out hover:border-primary gap-4 p-4 min-h-8 max-h-48 overflow-y-scroll rounded-lg border-surface border-[1px]">
          {filteredData.map((item) => (
            <div className="flex gap-2">
                <Radio.Item 
                  id={item.id} 
                  value={item.id} 
                  name="organization"
                  onChange={handleSelectChange} 
                  className="rounded"
                  required
                  checked={selectedItem === item.id}
                >
                    <Radio.Indicator>
                        <Check className="h-4 w-4 stroke-2" />
                    </Radio.Indicator>
                </Radio.Item>
                <label htmlFor={item.id} className="">
                <Typography className="block text-sm font-semibold">
                  {item.legal_name}
                </Typography>
                <Typography type="small" className="text-foreground">
                  {item.id}
                </Typography>
                </label>
            </div>
          ))}
        </Radio>
    </div>
)};

export default SearchableDropdown;