import React from 'react';
import { useLoading } from './loadingcontext';

const LoadingBar: React.FC = () => {
  const { loading } = useLoading();

  return loading ? (
    <div className="loader">
        <div className="move bg-primary">
        </div>
    </div>
  ) : null;
};

export default LoadingBar;


