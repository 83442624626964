import React, { useState,ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Typography, Switch } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormPage: React.FC = () => {
  const [formData, setFormData] = useState({ legal_name: '', alias: '', active: true, description: '' });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevData) => ({ ...prevData, active: checked }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('Kein Token gefunden, bitte einloggen');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/organizations/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const id = data.id;
        toast.success('Cluster angelegt!');
        navigate(`/organizations/${id}`); // Bei Erfolg zur neuen Seite navigieren
      } else {
        toast.error('Fehler beim Senden der Daten.');
        console.error('Fehler beim Senden der Daten');
      }
    } catch (error) {
      toast.error('Fehler:'+ error);
      console.error('Fehler:', error);
    }
  };

  return (
    <div className="w-full px-2">
        <div>
            <Typography type="h6">Organization</Typography>

            <Typography className="mt-1">
                Organization Add
            </Typography>
        </div>
        <hr className="-mx-3 my-3 border-secondary" />
      <form className="p-6 rounded shadow-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Name:
          </label>
          <Input className="w-80">
            <Input.Field 
                autoFocus
                placeholder="Customer Name" 
                type="text"
                id="legal_name"
                value={formData.legal_name}
                onChange={handleChange}
                color="primary"
                required
                className="w-full px-3 py-2 border rounded"
            />
          </Input>
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Alias:
          </label>
          <Input className="w-80">
            <Input.Field 
                placeholder="Alias" 
                type="text"
                id="alias"
                value={formData.alias}
                onChange={handleChange}
                color="primary"
                required
                className="w-full px-3 py-2 border rounded"
            />
          </Input>
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Description:
          </label>
          <Input className="w-80">
            <Input.Field 
                placeholder="" 
                type="text"
                id="description"
                value={formData.description}
                onChange={handleChange}
                color="primary"
                className="w-full px-3 py-2 border rounded"
            />
          </Input>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-semibold mb-1">
            Active:
          </label>
          <Switch 
            id="active"
            // value="true"
            color="primary" 
            onChange={handleSwitchChange}
            defaultChecked
            // disabled
            className="after:shadow-sm after:shadow-black/10" />
        </div>

        <Button 
            type="submit" 
            className="w-80 bg-primary text-white py-2 rounded"
        >
          Absenden
        </Button>
      </form>
    </div>
  );
};

export default FormPage;