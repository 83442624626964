import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { useReactTable, ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel } from "@tanstack/react-table";
import { Search, EditPencil, PlusCircle } from "iconoir-react";
import { NavLink } from "react-router-dom";
import Blob from '../ui-components/blob/blob';
import { Typography, Button, IconButton, Tooltip, Input } from "@material-tailwind/react";
import useFetch from '../ui-components/fetch/fetch.js'

const TABLE_HEAD: ColumnDef<Row>[] = [
  { header: "Legal Name", accessorKey: "legal_name" },
  { header: "Alias", accessorKey: "alias" },
  { header: "Description", accessorKey: "description" },
  { header: "", accessorKey: "edit" },
];

interface Row {
  id: string;
  active: boolean;
  legal_name: string;
  description: string;
  alias: string;
}

export default function TransactionsTable() {
  //const [globalFilter, setGlobalFilter] = useState("");
  var base_url = `${process.env.REACT_APP_API_URL}/api/v1/organizations/`;
  var reload= 30000;
  const [ url, setUrl ] = useState(base_url);
  const [ globalFilter, setGlobalFilter ] = useState("");
  const [ pages, setPages ] = useState(0);
  const [ data, pagination ] = useFetch({url: url, reloadInterval: reload});

  useEffect(() => { 
    const entries: number = (pagination as any)?.count ?? 0;
    setPages(Math.ceil(entries / 100));
  }, [pagination])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setUrl(`${base_url}?page=${value}`);
  };

  const table = useReactTable({
    data,
    columns: TABLE_HEAD,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6">Organizations</Typography>
          <Typography className="mt-1">Organizations Overview</Typography>
        </div>

        <div className="flex w-full shrink-0 gap-2 md:w-max">
          <NavLink to="/organizations/add">
            <Button variant="solid">
              <PlusCircle className="me-2 h-4 w-4 stroke-2" />
              Add Organization
            </Button>
          </NavLink>
          <div className="w-full md:w-72">
            <Input>
              <Input.Field
                autoFocus
                placeholder="Search"
                value={globalFilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
              />
              <Input.Icon placement="end">
                <Search className="h-5 w-5" />
              </Input.Icon>
            </Input>
          </div>
        </div>
      </div>
      <hr className="-mx-3 my-3 border-secondary" />
      <Pagination 
        count={pages} 
        className="bg-primary" 
        onChange={handleChange}
      />
      <div className="w-full overflow-hidden rounded-lg border border-primary/20 overflow-x-scroll">
        <table className="w-full text-left">
          <thead className="border-b border-primary bg-surface-light text-sm font-medium text-foreground dark:bg-primary/10">
            <tr>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-2.5 py-2 text-start font-medium">
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="transition ease-in-out duration-300 hover:bg-surface-light hover:dark:bg-primary/10">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-4 border-b border-surface-light">
                    {cell.column.id === "legal_name" ? (
                      <div className="flex items-center gap-3">
                        {row.original.active ? <Blob state="green" /> : <Blob state="red" />}
                        <Typography type="small">{cell.getValue()}</Typography>
                      </div>
                    ) : cell.column.id === "edit" ? (
                      <Tooltip>
                        <Tooltip.Trigger as={IconButton} color="secondary" variant="ghost">
                          <NavLink to={"/organizations/" + row.original.id}>
                            <EditPencil className="h-4 w-4" />
                          </NavLink>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                          Edit
                          <Tooltip.Arrow />
                        </Tooltip.Content>
                      </Tooltip>
                    ) : (
                      <Typography type="small">{cell.getValue()}</Typography>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}