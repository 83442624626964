import React, {useEffect, useState } from 'react';
import { useLoading } from '../loadbar/loadingcontext';

const useFetch = ({url = "", reloadInterval = 5000}) => {
    const { setLoading } = useLoading();
    const [ data, setData] = useState([]);
    const [ pagination, setPagination] = useState([]);
    const [ error, setError] = useState("");

    useEffect(() => { 
        const fetchData = async () => {
            setLoading(true);
            const token = localStorage.getItem('authToken');
      
            if (!token) {
              console.error('Kein Token gefunden, bitte einloggen');
              return;
            }
            try {
              const response = await fetch(url, {
                method: 'GET',
                headers: {
                  'Authorization': `Token ${token}`
                }
              });
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const result = await response.json();
              setPagination(result);
              setData(result['results']);
            } catch (error) {
              console.error('Error fetching data:', error);
              setError('An error occurred while fetching the data. Please try again later.');
            } finally {
              setLoading(false);
            }
        };
        fetchData();
          const intervalId = setInterval(fetchData, reloadInterval); // Setze das Intervall auf 5000ms (5 Sekunden)
          return () => clearInterval(intervalId); // Bereinige das Intervall beim Unmount
    }, [url]);
    console.log(data, pagination)
  return [data, pagination];
};

export default useFetch;