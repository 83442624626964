import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Search, EditPencil } from "iconoir-react";
import { NavLink } from "react-router-dom";
import useFetch from '../ui-components/fetch/fetch.js'

import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import { PaginationItem } from '@mui/material';

interface Row {
  id: string;
  name: string;
  from_block: string;
  state: string;
  workspace: string;
  organization: string;
  provider: string;
  conditions: any;
}

const TABLE_HEAD: ColumnDef<Row>[] = [
  { header: "Name", accessorKey: "name" },
  { header: "Block", accessorKey: "from_block" },
  { header: "state", accessorKey: "state" },
  { header: "Workspace", accessorKey: "workspace.name" },
  { header: "Conditions", accessorKey: "conditions" },
  { header: "Organization", accessorKey: "organization.name" },
  { header: "", accessorKey: "edit" },
];

export default function TransactionsTable() {
  const reload= 30000;
  const base_url = `${process.env.REACT_APP_API_URL}/api/v1/blocks/`;
  const [ url, setUrl ] = useState(base_url);
  const [ globalFilter, setGlobalFilter ] = useState("");
  const [ pages, setPages ] = useState(1);
  const [ page, setPage ] = useState(1)
  const [ searchfilter, setSearchFilter ] = useState("");
  const [ data, pagination ] = useFetch({url: url, reloadInterval: reload});

  useEffect(() => { 
    const entries: number = (pagination as any)?.count ?? 0;
    setPages(Math.ceil(entries / 100));
  }, [pagination])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setUrl(`${base_url}?page=${value}&name=${searchfilter}`);
  };

  const handleSearchFilter = (value: string) => {
    console.log(value)
    setSearchFilter(value);
    if (value.length > 3 ||value.length == 0)
      setUrl(`${base_url}?name=${value}`);
  };

  const table = useReactTable({
    data,
    columns: TABLE_HEAD,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6">Blocks</Typography>
          <Typography className="mt-1">List</Typography>
        </div>
        <div className="flex w-full shrink-0 gap-2 md:w-max">
          <div className="w-full md:w-72">
            <Input>
              <Input.Field
                autoFocus
                placeholder="Enter at least 3 chars"
                //value={searchfilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSearchFilter(e.currentTarget.value)
                }
              />
              <Input.Icon placement="end">
                <Search className="h-5 w-5" />
              </Input.Icon>
            </Input>
          </div>
        </div>
      </div>
      <hr className="-mx-3 my-3 border-secondary" />
      <div className="w-full overflow-hidden rounded-lg border border-primary/20 overflow-x-scroll">
        <Pagination 
          count={pages} 
          className="bg-primary" 
          onChange={handleChange}
        />
        <table className="w-full text-left">
          <thead className="border-b border-primary bg-surface-light text-sm font-medium text-foreground dark:bg-primary/10">
            <tr>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-2.5 py-2 text-start font-medium">
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="transition ease-in-out duration-300 hover:bg-surface-light hover:dark:bg-primary/10">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-4 border-b border-surface-light">
                    {cell.column.id === "edit" ? (
                      <Tooltip>
                        <Tooltip.Trigger
                          as={IconButton}
                          color="secondary"
                          variant="ghost"
                        >
                          <NavLink to={"/blocks/" + row.original.id}>
                            <EditPencil className="h-4 w-4" />
                          </NavLink>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                          Edit
                          <Tooltip.Arrow />
                        </Tooltip.Content>
                      </Tooltip>
                    ) : cell.column.id === "conditions" ? (
                        <Typography type="small">  
                          <div>{row.original.conditions[0]?.reason}</div>
                        </Typography>
                    ) : (
                      <Typography type="small">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Typography>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}