import * as React from "react";
//import Login from "../login/login"
import { NavLink } from "react-router-dom";

import {
  Card,
  List,
  Chip,
  Collapse,
  Input,
} from "@material-tailwind/react";

import {
  Folder,
  Mail,
  MoreHorizCircle,
  NavArrowRight,
  Search,
  SendDiagonal,
  UserXmark,
} from "iconoir-react";

const Links = [
  {
    icon: Mail,

    title: "List",

    href: "/cluster/list",

    badge: 1,
  },

  {
    icon: SendDiagonal,

    title: "Add",

    href: "/cluster/add",
  }
];

export default function SidebarWithSearch() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="max-w-[280px] py-[55px] absolute bottom-0 h-dvh border-surface border-r">
      <Card.Body className="p-3">

        <Input>
          <Input.Icon>
            <Search className="h-full w-full" />
          </Input.Icon>

          <Input.Field type="search" placeholder="Search here..." />
        </Input>

        <List className="mt-3">
          {Links.map(({ icon: Icon, title, href, badge }) => (
            <NavLink 
              to={href}
              className={({ isActive }) => {
                return isActive ? "text-primary" : "";
              }}
            >  
              <List.Item key={title} href={href}>
                <List.ItemStart>
                  <Icon className="h-[18px] w-[18px]" />
                </List.ItemStart>
                <NavLink 
                  to={href}
                  className={({ isActive }) => {
                    return isActive ? "text-primary" : "";
                  }}
                >  
                {title}
                </NavLink>

                {badge && (
                  <List.ItemEnd>
                    <Chip size="sm" variant="ghost">
                      <Chip.Label>{badge}</Chip.Label>
                    </Chip>
                  </List.ItemEnd>
                )}
              </List.Item>
            </NavLink>
          ))}

          <hr className="-mx-3 my-3 border-secondary" />

          <List.Item onClick={() => setIsOpen((cur) => !cur)}>
            <List.ItemStart>
              <MoreHorizCircle className="h-[18px] w-[18px]" />
            </List.ItemStart>
            More
            <List.ItemEnd>
              <NavArrowRight
                className={`h-4 w-4 ${isOpen ? "rotate-90" : ""}`}
              />
            </List.ItemEnd>
          </List.Item>

          <Collapse open={isOpen}>
            <List>
              <List.Item>
                <List.ItemStart>
                  <Folder className="h-[18px] w-[18px]" />
                </List.ItemStart>
                Namespaces
              </List.Item>

              <List.Item>
                <List.ItemStart>
                  <UserXmark className="h-[18px] w-[18px]" />
                </List.ItemStart>
                Services
              </List.Item>

              <List.Item>
                <List.ItemStart>
                  <Folder className="h-[18px] w-[18px]" />
                </List.ItemStart>
                RBAC
              </List.Item>
            </List>
          </Collapse>



        </List>
      </Card.Body>

    </div>
  );
}
