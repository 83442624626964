import React from 'react';

interface Api {
  state: string;
}

const GetBlob: React.FC<Api> = ({state}) => {
    let class_name = `blob ${state}`
  return (
        <div 
          className={class_name}
        >
        </div>
  );
};

export default GetBlob;