import React from "react";
import { Typography, Button } from "@material-tailwind/react";

export function ErrorSection() {
  return (
      <div className="mx-auto grid place-items-center text-center px-8">
        <div>
          <Typography
            variant="h1"
            color="primary"
            className="mt-10 !text-3xl !leading-snug md:!text-4xl"
          >
            Oops...
          </Typography>
          <Typography
            variant="h1"
            className="mt-10 !text-3xl !leading-snug md:!text-4xl"
          >
            It looks like something went wrong.
          </Typography>
          <div className="my-10">
          <img src="https://ayedo.de/aliens/ayedo-alien-team-3-trimmed.png" />
          </div>
        </div>
      </div>
  );
}

export default ErrorSection;