import React, { useState, useEffect } from 'react';
import { 
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Input 
} from "@material-tailwind/react";

interface Hidden {
  className: string
}
const Login: React.FC<Hidden> = ({className}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      // Überprüfen, ob ein Token im localStorage vorhanden ist
      const token = localStorage.getItem('authToken');
      if (token) {
        setIsLoggedIn(true);
      }
    }, []);
  
    const handleLogin = async (event: React.FormEvent) => {
      event.preventDefault();
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });
  
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('expiry', data.expiry);
          setIsLoggedIn(true); // Status nach erfolgreichem Login aktualisieren
          setError(null);
          window.location.reload();
        } else {
          setError('Login fehlgeschlagen. Bitte Nutzername und Passwort überprüfen.');
        }
      } catch (err) {
        setError('Fehler beim Login. Bitte später erneut versuchen.');
        console.error('Login Fehler:', err);
      }
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('expiry');
        setIsLoggedIn(false); // Status zurücksetzen
        window.location.reload();
      };    

    if (isLoggedIn) {
      className = `text-secondary  ${className}`
      return (
        <Button size="sm" className={className} onClick={handleLogout}>Logout</Button>
      );
    }

    return (
      <div className="ayedo-background">
        <section className="grid text-center h-screen items-center p-2 w-80 mx-auto">
            <div>
                <Card className="p-5 space-y-4 border-primary shadow-lg backdrop-blur-sm bg-primary/5 shadow-primary/20">
                    <CardHeader color="secondary" className="relative">
                      <Typography variant="h5" color="primary" className="mb-1">
                        Sign In
                      </Typography>
                      <Typography className="mb-2 text-foreground font-normal text-[18px]">
                        Enter your username and password to sign in
                      </Typography>
                    </CardHeader>
                    <CardBody>
                    <form onSubmit={handleLogin} className="space-y-4">
                        <Input>
                            <Input.Field placeholder="Username" 
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e: any) => setUsername(e.target.value)}
                                required
                            />
                        </Input>
                            <Input>
                                <Input.Field placeholder="Password" 
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e: any) => setPassword(e.target.value)}
                                    required
                                />
                            </Input>
                        <Button className="gap-2 rounded mx-5">Einloggen</Button>
                    </form>
                    </CardBody>
                    <CardFooter className="pt-0">
                        {error && <p style={{color: 'red'}}>{error}</p>}
                    </CardFooter>
                </Card>
          </div>
        </section>
      </div>
      );
};

export default Login;