import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Search, EditPencil, PlusCircle, InfoCircle } from "iconoir-react";
import { NavLink } from "react-router-dom";
import PromChart from '../ui-components/charts/prom'
import useFetch from '../ui-components/fetch/fetch.js'
import Moment from 'react-moment';
import 'moment-timezone';
import Blob from '../ui-components/blob/blob';
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Input,
  Spinner,
} from "@material-tailwind/react";

interface Row {
  id: string;
  name: string;
  state: string;
  //provider: string;
  kind: string;
  kubernetes_version: string;
  //system_check: SystemCheck;
  reconciliation_running: boolean;
  conditions: any;
  kubeconfig_ca_cert_expiry_date: string;
  kubeconfig_client_cert_expiry_date: string;
  api_server_cert_expiry_date: string;
}

const TABLE_HEAD: ColumnDef<Row>[] = [
  { header: "Name", accessorKey: "name" },
  //{ header: "Max CPU", accessorKey: "cpu_chart" },
  //{ header: "Max Mem", accessorKey: "memory_chart" },
  { header: "Version", accessorKey: "kubernetes_version" },
  { header: "Condition", accessorKey: "conditions" },
  // { header: "api cert", accessorKey: "api_server_cert_expiry_date" },
  { header: "Kubeconfig valid", accessorKey: "readable_kubeconfig_client_cert_expiry_date" },
  { header: "", accessorKey: "edit" },
];

export default function TransactionsTable() {
  const reload= 30000;
  const base_url = `${process.env.REACT_APP_API_URL}/api/v1/kubernetes/clusters/`;
  const [ url, setUrl ] = useState(base_url);
  const [ globalFilter, setGlobalFilter ] = useState("");
  const [ pages, setPages ] = useState(0);
  const [ data, pagination ] = useFetch({url: url, reloadInterval: reload});

  useEffect(() => { 
    const entries: number = (pagination as any)?.count ?? 0;
    setPages(Math.ceil(entries / 100));
  }, [pagination])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setUrl(`${base_url}?page=${value}`);
  };

  const truncate = (str: string) => {
    return str.length > 80 ? str.substring(0, 100) + "..." : str;
  }

  const table = useReactTable({
    data,
    columns: TABLE_HEAD,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6">Cluster</Typography>
          <Typography className="mt-1">Cluster Overview</Typography>
        </div>
        <div className="flex w-full shrink-0 gap-2 md:w-max">
          <div className="w-full md:w-72">
            <Input>
              <Input.Field
                autoFocus
                placeholder="Search"
                value={globalFilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setGlobalFilter(e.target.value)
                }
              />
              <Input.Icon placement="end">
                <Search className="h-5 w-5" />
              </Input.Icon>
            </Input>
          </div>
        </div>
      </div>

      <hr className="-mx-3 my-3 border-secondary" />
      <Pagination 
        count={pages} 
        className="bg-primary" 
        onChange={handleChange}
      />
      <div className="w-full overflow-hidden rounded-lg border border-primary/20 overflow-x-scroll">
        <table className="w-full text-left">
          <thead className="border-b border-primary bg-surface-light text-sm font-medium text-foreground dark:bg-primary/10">
            <tr>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-2.5 py-2 text-start font-medium"
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="transition ease-in-out duration-300 hover:bg-surface-light hover:dark:bg-primary/10">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="p-4 border-b border-surface-light">
                      {cell.column.id === "edit" ? (
                        <Tooltip>
                          <Tooltip.Trigger
                            as={IconButton}
                            color="secondary"
                            variant="ghost"
                          >
                            <NavLink to={"/cluster/" + row.original.id}>
                              <EditPencil className="h-4 w-4" />
                            </NavLink>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            Edit
                            <Tooltip.Arrow />
                          </Tooltip.Content>
                        </Tooltip>
                      ) : (
                        <div className="flex items-center gap-3">
                          {cell.column.id === "name" && (
                            row.original.state === "READY" ? (
                              <Blob state="green" />
                            ) : (
                              <Blob state="red" />
                            )
                          )}
                          {cell.column.id === "name" && (
                            row.original.reconciliation_running === true ? (
                              <Spinner color="primary" size="sm" />
                            ) : (
                              <div>-</div>
                            )
                          )}
                          {cell.column.id === "cpu_chart" && (
                            <div>
                              {/* <PromChart 
                              query={`max(1-rate(node_cpu_seconds_total{mode="idle", job="node-exporter",cluster="${row.original.name}"}[15m]))`}
                              queryType='query-range'
                              startDate='now-12h'
                              height='50px'
                              /> */}
                            </div>
                          )}
                          {cell.column.id === "memory_chart" && (
                            <div>
                              {/* <PromChart 
                              query={`max(node_memory_MemTotal_bytes{job="node-exporter", cluster="${row.original.name}"} - node_memory_MemAvailable_bytes{job="node-exporter", cluster="${row.original.name}"}) / max(node_memory_MemTotal_bytes{job="node-exporter", cluster="${row.original.name}"})`}
                              queryType='query-range'
                              startDate='now-12h'
                              height='50px'
                              /> */}
                            </div>
                          )}
                          <Typography type="small">
                          <NavLink to={"/cluster/" + row.original.id}>
                            { cell.column.id !== "conditions" ? (
                                flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )
                              ):(
                                row.original.conditions.map((condition: any, index: number) => {
                                  return  ( 
                                    <li>{truncate(condition.reason)}</li>
                                  ); 
                                })
                              )
                            }
                          </NavLink>
                          </Typography>
                          {/* {cell.column.id === "readable_api_server_cert_expiry_date" && (
                            <>
                            {row.original.conditions.map((condition, index) => {
                              return condition.type === "API_SERVER_CERT_EXPIRED" ? ( 
                                <Blob key={index} state='red' />
                              ) : (
                                condition.type === "API_SERVER_CERT_EXPIRES_SOON" && (
                                  <Blob key={index} state='orange' />
                                )
                              );
                            })}
                            </>
                          )} */}
                          {cell.column.id === "kubernetes_version" && (
                            row.original.conditions.map((condition: any, index: number) => {
                              return condition.type === "KUBERNETES_VERSION_IS_BEHIND_STABLE" && ( 
                                <Blob key={index} state='orange' />
                              );
                            })
                          )}
                          {cell.column.id === "readable_kubeconfig_client_cert_expiry_date" && (
                            <>
                            <Typography type="small">
                              <Tooltip>
                                <Tooltip.Trigger>
                                  <Moment date={row.original.kubeconfig_client_cert_expiry_date} fromNow />
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    <Moment date={row.original.kubeconfig_client_cert_expiry_date} format="DD.MM.YYYY HH:MM" />
                                  <Tooltip.Arrow />
                                </Tooltip.Content>
                              </Tooltip>
                            </Typography>                            
                            {row.original.conditions.map((condition: any, index: number) => {
                              return condition.type === "KUBECONFIG_CLIENT_CERT_EXPIRED" ? ( 
                                <Blob key={index} state='red' />
                              ) : (
                                condition.type === "KUBECONFIG_CLIENT_CERT_EXPIRES_SOON" && (
                                  <Blob key={index} state='orange' />
                                )
                              );
                            })}
                            </>
                          )}
                          {/* {cell.column.id === "state" && (
                            row.original.state === "DEGRADED" && (
                              <Tooltip>
                              <Tooltip.Trigger as={IconButton} variant="ghost">
                                <InfoCircle className="h-5 w-5" />
                              </Tooltip.Trigger>
                              <Tooltip.Content className="border-primary rounded bg-black p-0 m-0">
                                <Typography type="small">
                                  <pre className="bg-black/95 text-primary/80">{JSON.stringify(row.original.conditions, null, "\t")}</pre>
                                </Typography>
                                <Tooltip.Arrow />
                              </Tooltip.Content>
                            </Tooltip>
                            )
                          )} */}
                        
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/*
        Replace or adjust the following pagination if needed, as the example pagination buttons
        created were static based on your previous example.
      */}
    </div>
  );
}