import React, { useState, useEffect } from 'react';
import Main from './site/main';
import Login from './login/login';
import LoadingBar from './ui-components/loadbar/loadingbar';
import { ToastContainer } from 'react-toastify';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const expiryString = localStorage.getItem('expiry');

    if (expiryString) {
      // Shorten the date string to retain only the first 3 milliseconds digits.
      const validExpiryString = expiryString.replace(/(\.\d{3})\d+Z$/, '$1Z');

      if (isPastDate(validExpiryString)) {
        localStorage.removeItem('authToken'); // Remove the token
        localStorage.removeItem('expiry'); // Remove the token
        setIsLoggedIn(false); // Reset the status
        window.location.reload(); // Reload site
        console.log("tokenexpired")
      } else {
        setIsLoggedIn(true);
      }
    }
  }, []);

  function isPastDate(dateString: string): boolean {
    // Get the current date and time
    const now: Date = new Date();

    // Convert the given string into a Date object
    const storageDate: Date = new Date(dateString);

    if (isNaN(storageDate.getTime())) {
      console.error('Invalid date');
    }

    // Compare whether the given date is in the past
    return storageDate < now;
  }

  return (
    <div className="App">
        { isLoggedIn ? (
          <div>
            <Main />
            <LoadingBar />
            <ToastContainer position="bottom-right" theme="dark"/>
          </div>
        ) : (
          <Login className=""/>
        )}
    </div>
  );
}

export default App;