import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
//import ClusterSyncFromGitlab from "../actions/clusterSyncFromGitlab";
import Discover from "../actions/discover";
import Reconcile from "../actions/reconcile";
import PromChart from "../../ui-components/charts/prom";
import JsonEditor from "../../ui-components/editor/monaco";
import { NavArrowDown, EyeSolid, EyeClosed } from "iconoir-react";
import Blob from '../../ui-components/blob/blob'
import {
  Card,
  Typography,
  Tabs,
  Accordion,
  Chip
} from "@material-tailwind/react";

interface Row {
  id: string;
  name: string,
  remote_address: string,
  state: string,
  provider: string,
  reconciliation_enabled: boolean;
  reconciliation_running: boolean;
  reconciliation_task_id: string;
  last_reconciliation: string;
  discovery_enabled: boolean;
  discovery_running: boolean;
  discovery_task_id: string;
  last_discovery: string;
  repair_running: boolean;
  repair_task_id: string;
  last_repair: string;
  kind: string;
  scope: string;
  labels: [];
}


export default function TransactionsTable() {

    const [data, setData] = useState<Row>({
      id: "",
      name: "",
      remote_address: "",
      state: "",
      provider: "",
      reconciliation_enabled: false,
      reconciliation_running: false,
      reconciliation_task_id: "",
      last_reconciliation: "",
      discovery_enabled: false,
      discovery_running: false,
      discovery_task_id: "",
      last_discovery: "",
      repair_running: false,
      repair_task_id: "",
      last_repair: "",
      kind: "",
      scope: "",
      labels: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          const token = localStorage.getItem('authToken');

          if (!token) {
            console.error('Kein Token gefunden, bitte einloggen');
            return;
          }
          try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/endpoints/${id}/`, {
              method: 'GET',
              headers: {
                  'Authorization': `Token ${token}`
              }
              });
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const result = await response.json();
              setData(result);
          } catch (error) {
              console.error('Error fetching data:', error);
              setError('An error occurred while fetching the data. Please try again later.');
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
        const intervalId = setInterval(fetchData, 5000);

        return () => clearInterval(intervalId);
    }, [setLoading]);


  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6" color="primary">Endpoint</Typography>

          <Typography className="mt-1">
          {data.remote_address} 
          </Typography>
        </div>
      </div>
      
      <hr className="-mx-3 my-3 border-secondary" />

      <div className="flex gap-4">
        <Discover cluster_id={data.id} />
        <Reconcile cluster_id={data.id} />
      </div>
   
      <hr className="-mx-3 my-3 border-secondary" />
      <div className="mt-6 border-t border-gray-600">
        <dl className="divide-y divide-gray-600">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">kind</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.kind.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">scope</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.scope.toString()}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">labels</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0"><pre>{JSON.stringify(data.labels, null,2)}</pre></dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.last_reconciliation}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">reconciliation running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.reconciliation_enabled.toString()} / {data.reconciliation_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery enabled / last_run</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.last_discovery}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">discovery running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.discovery_enabled.toString()} / {data.discovery_task_id}</dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm/6 font-medium text-gray-200">repair running / task_id</dt>
            <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{data.repair_running.toString()} / {data.repair_task_id}</dd>
          </div>
        </dl>
      </div>

      <hr className="-mx-3 my-3 border-secondary" />
      <div>
 
      <Accordion>
      <Accordion.Item value="raw">
        <Accordion.Trigger>
          Raw
          <NavArrowDown className="h-4 w-4 data-[open=true]:rotate-180" />
        </Accordion.Trigger>
        <Accordion.Content>
        <Card className="m-1 space-y-4">
            <JsonEditor jsonData={data} />
            </Card>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
        <div className="w-full rounded-lg">

        </div>
      </div>

    </div>
  );
}
