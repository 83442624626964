import Navbar from './navbar';
import Sidebar from './sidebar';

function main() {
  return (
      <div>
        {/* <Sidebar /> */}
        <Navbar />
      </div>
  );
}

export default main;