import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

interface Chart {
    query: string;
    queryType: string;
    customer?: string;
    xLabel?: boolean;
    yLabel?: boolean;
    xMax?: number;
    startDate?: string;
    beginAtZero?: boolean;
    cluster?: string;
    height?: string;
    width?: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

const PrometheusLineChart: React.FC<Chart> = (
    {
        query = 'avg(1-rate(node_cpu_seconds_total{mode="idle", job="node-exporter"}[15m]))', 
        queryType = "query-range", 
        customer, 
        xLabel = false, 
        yLabel = false, 
        xMax = 1, 
        startDate = "now-24h", 
        beginAtZero = true,
        cluster = "",
        height = "30px",
        width = "150px"
    }) => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: 'Metric Name',
        data: [],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('authToken');

        if (!token) {
          console.error('Kein Token gefunden, bitte einloggen');
          return;
        }
      try {
        //const query = 'sum(increase(kube_pod_container_status_restarts_total[1h])) > 0';
        //const query = '';
        const encodedQuery = encodeURIComponent(query);
        //const username = 'superadmin-s';
        //const password = '84769rvdbtn8079p8w7o6ve5rv96bdfn8o798pzs6v75ordi-s';
        //const base_url= `https://metrics-cors.in.v1.data.ayedo.network/api/v1/${queryType}?query`
        const base_url= `${process.env.REACT_APP_API_URL}/api/v1/metrics/${queryType}?query`
        const response = await fetch(`${base_url}=${encodedQuery}&start=${startDate}&end=now`, { 
            headers: {
                'Authorization': `Token ${token}`
              }
         });
        console.log(query);
        const data = await response.json();
        //console.log(JSON.stringify(data.data.result))
        const prometheusData = data.data.result[0].values;

        const labels = prometheusData.map((value: [number, string]) =>
          new Date(value[0] * 1000).toLocaleString()
        );
        const dataValues = prometheusData.map((value: [number, string]) =>
          parseFloat(value[1])
        );
        

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'CPU',
              data: dataValues,
              fill: true,
              backgroundColor: 'rgba(173,208,21,0.4)',
              borderColor: 'rgba(173,208,21,1)',
              borderWidth: 1
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data from Prometheus', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="border-secondary border rounded">
      <Line 
        height={height}
        width={width}
        data={chartData}
        options={{
          elements: {
            point: {
                pointStyle: false
            }
          },
          layout: {
            padding: 0,
            autoPadding: false
        },
          scales: {
            x: {
              display: xLabel // Entfernt die Anzeige der X-Achse vollständig
            },
            y: {
              beginAtZero: beginAtZero,
              max: xMax, // Maximalwert der Y-Achse festlegen
              display: yLabel
            }
          },
          plugins: {
            legend: {
                display: false // Deaktiviert die Legende
              },
            tooltip: {
            enabled:true,
              callbacks: {
                label: function(context) {
                  // Optional: Anpassung des Tooltips
                  return `${context.raw}`;
                }
              }
            }
          }
        }}
      />
    </div>
  );
};

export default PrometheusLineChart;