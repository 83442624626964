import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Input, Button, Typography, Switch, Radio } from "@material-tailwind/react";
import { toast } from 'react-toastify';
import { useLoading } from '../ui-components/loadbar/loadingcontext';
import 'react-toastify/dist/ReactToastify.css';
import OrgaDropdown from '../organizations/actions/formOrganizationDropdown';
import ProviderDropdown from './actions/formProviderDropdow';
import { Check, PlusCircle } from "iconoir-react";

interface Row {
  id: string,
  active: boolean,
  legal_name: string,
  description: string,
  alias: string,
}

interface Data {
  name: string;
  gitlab_project_id: string;
  organization: string;
  active: boolean;
  provider: string;
}

const FormPage: React.FC = () => {
  const [formData, setFormData] = useState<Data>({ name: '', gitlab_project_id: '', organization: '', active: true, provider: '' });
  const navigate = useNavigate();

  const [data, setData] = useState<Row[]>([]);
  const [error, setError] = useState("");
  // const [loading, setLoading] = useState(true);
  const { setLoading } = useLoading();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        const token = localStorage.getItem('authToken');

        if (!token) {
          console.error('Kein Token gefunden, bitte einloggen');
          return;
        }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/organizations/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
        });
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result['results']);
    } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching the data. Please try again later.');
    } finally {
      setLoading(false);
    }
    };

    fetchData();
    // const intervalId = setInterval(fetchData, 10000); // Setze das Intervall auf 5000ms (5 Sekunden)

    // return () => clearInterval(intervalId); // Bereinige das Intervall beim Unmount
}, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
    setFormData((prevData) => ({ ...prevData, organization: selectedValue }));
  };

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = data.filter(item =>
    item.legal_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevData) => ({ ...prevData, active: checked }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('Kein Token gefunden, bitte einloggen');
      return;
    }
    console.log(JSON.stringify(formData))
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/workspaces/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const id = data.id;
        toast.success('Cluster angelegt!');
        navigate(`/cluster/${id}`); // Bei Erfolg zur neuen Seite navigieren
      } else {
        toast.error('Fehler beim Senden der Daten.');
        console.error('Fehler beim Senden der Daten');
      }
    } catch (error) {
      toast.error('Fehler:'+ error);
      console.error('Fehler:', error);
    }
  };

  return (
    <div className="px-2">
        <div>
            <Typography type="h6">Cluster</Typography>

            <Typography className="mt-1">
                Import Workspace from Gitlab
            </Typography>
        </div>
        <hr className="-mx-3 my-3 border-secondary" />
      <form className="p-6 rounded max-w-3xl shadow-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Name:
          </label>
          <Input className="">
            <Input.Field 
                autoFocus
                placeholder="my-cluster-name-1" 
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                color="primary"
                required
                className="w-full px-3 py-2 border rounded"
            />
          </Input>
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Provider:
          </label>
          <ProviderDropdown setFormData={setFormData} />
          {/* <Input className="">
            <Input.Field 
                placeholder="hetzner_cloud" 
                type="text"
                id="provider"
                value={formData.provider}
                onChange={handleChange}
                color="primary"
                required
                className="w-full px-3 py-2 border rounded"
            />
          </Input>*/}
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold mb-1">
            Gitlab Project ID:
          </label>
          <Input className="">
            <Input.Field 
                placeholder="123" 
                type="text"
                id="gitlab_project_id"
                value={formData.gitlab_project_id}
                onChange={handleChange}
                color="primary"
                required
                className="w-full px-3 py-2 border rounded"
            />
          </Input>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-semibold mb-1">
            Organization ID:
          </label>
          <OrgaDropdown setFormData={setFormData} />
        {/* <div>
      <div className="flex mb-2">
        <Input className="" id="test">
          <Input.Field
              placeholder="Filter"
              type="text"
              id="organization"
              value={searchTerm}
              onChange={handleSearch}
              className="mb-4"
          />
        </Input>
        <NavLink 
          to="/organizations/add"
          className="ml-2"
        >  
          <Button variant="solid">
          <PlusCircle className="me-2 h-4 w-4 stroke-2" />
            Create Organization
          </Button>
        </NavLink>
        </div>
        <Radio className="hover:transition-all duration-700 ease-in-out hover:border-primary gap-4 p-4 min-h-8 h-24 overflow-y-scroll rounded-lg border-surface border-[1px]">
          {filteredData.map((item) => (
            <div className="flex gap-2">
                <Radio.Item 
                  id={item.id} 
                  value={item.id} 
                  name="organization"
                  onChange={handleSelectChange} 
                  className="rounded"
                  required
                  checked={selectedItem === item.id}
                >
                    <Radio.Indicator>
                        <Check className="h-4 w-4 stroke-2" />
                    </Radio.Indicator>
                </Radio.Item>
                <label htmlFor={item.id} className="">
                <Typography className="block text-sm font-semibold">
                  {item.legal_name}
                </Typography>
                <Typography type="small" className="text-foreground">
                  {item.id}
                </Typography>
                </label>
            </div>
          ))}
        </Radio>
    </div> */}

        </div>
        {/* <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-semibold mb-1">
            Active:
          </label>
          <Switch 
            id="active"
            // value="true"
            color="primary" 
            onChange={handleSwitchChange}
            defaultChecked
            // disabled
            className="after:shadow-sm after:shadow-black/10" />
        </div> */}

        <Button 
            type="submit" 
            className="w-full bg-primary text-secondary py-2 rounded"
        >
          Absenden
        </Button>
      </form>
    </div>
  );
};

export default FormPage;