import React, { useState, useEffect } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Search, EditPencil } from "iconoir-react";
import { NavLink } from "react-router-dom";
import useFetch from '../ui-components/fetch/fetch.js'
import Blob from '../ui-components/blob/blob';
import {
  Typography,
  IconButton,
  Tooltip,
  Input,
  Spinner,
} from "@material-tailwind/react";
import Pagination from '@mui/material/Pagination';
interface Row {
  id: string;
  hostname: string;
  role: string;
  provider: string;
  default_ipv4: string;
  default_ipv6: string;
  organization: any;
  conditions: any;
  reconciliation_running: boolean;
  state: string;
  kind: string;
}

const TABLE_HEAD: ColumnDef<Row>[] = [
  { header: "Name", accessorKey: "hostname" },
  { header: "Organization", accessorKey: "organization" },
  { header: "Conditions", accessorKey: "conditions" },
  //{ header: "IPv4", accessorKey: "default_ipv4" },
  //{ header: "Role", accessorKey: "role" },
  { header: "Provider", accessorKey: "provider" },
  { header: "Kind", accessorKey: "kind" },
  { header: "", accessorKey: "edit" },
];

export default function TransactionsTable() {
  const reload= 30000;
  const base_url = `${process.env.REACT_APP_API_URL}/api/v1/hosts/`;
  const [ url, setUrl ] = useState(base_url);
  const [ globalFilter, setGlobalFilter ] = useState("");
  const [ pages, setPages ] = useState(0);
  const [ data, pagination ] = useFetch({url: url, reloadInterval: reload});

  useEffect(() => { 
    const entries: number = (pagination as any)?.count ?? 0;
    setPages(Math.ceil(entries / 100));
  }, [pagination])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setUrl(`${base_url}?page=${value}`);
  };

  const truncate = (str: string) => {
    return str.length > 80 ? str.substring(0, 100) + "..." : str;
  }

  const table = useReactTable({
    data,
    columns: TABLE_HEAD,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div>
          <Typography type="h6">Hosts</Typography>
          <Typography className="mt-1">Host Overview</Typography>
        </div>

        <div className="flex w-full shrink-0 gap-2 md:w-max">
          <div className="w-full md:w-72">
            <Input>
              <Input.Field
                autoFocus
                placeholder="Search"
                value={globalFilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setGlobalFilter(e.target.value)
                }
              />
              <Input.Icon placement="end">
                <Search className="h-5 w-5" />
              </Input.Icon>
            </Input>
          </div>
        </div>
      </div>
      <hr className="-mx-3 my-3 border-secondary" />
      <Pagination 
        count={pages} 
        className="bg-primary" 
        onChange={handleChange}
      />
      <div className="w-full overflow-hidden rounded-lg border border-primary/20">
        <table className="w-full text-left">
          <thead className="border-b border-primary bg-surface-light text-sm font-medium text-foreground dark:bg-primary/10">
            <tr>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-2.5 py-2 text-start font-medium"
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="transition ease-in-out duration-300 hover:bg-surface-light hover:dark:bg-primary/10">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="p-4 border-b border-surface-light">
                      {cell.column.id === "edit" ? (
                        <Tooltip>
                          <Tooltip.Trigger
                            as={IconButton}
                            color="secondary"
                            variant="ghost"
                          >
                            <NavLink to={"/hosts/" + row.original.id}>
                              <EditPencil className="h-4 w-4" />
                            </NavLink>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            Edit
                            <Tooltip.Arrow />
                          </Tooltip.Content>
                        </Tooltip>
                      ) : (
                        <div className="flex items-center gap-3">
                          {cell.column.id === "hostname" && (
                            row.original.state === "READY" ? (
                              <Blob state="green" />
                            ) : (
                              row.original.state === null ? (
                                <Blob state="orange" />
                              ) : (
                                <Blob state="red" />
                              )
                            )
                          )}
                          {cell.column.id === "hostname" && (
                            row.original.reconciliation_running === true ? (
                              <Spinner color="primary" size="sm" />
                            ) : (
                              <div>-</div>
                            )
                          )}
                          <Typography type="small">
                            { cell.column.id === "organization" ? (
                              <div>{row.original.organization.name}</div>
                            ):(
                              cell.column.id === "conditions" ? (
                                row.original.conditions.map((condition: any, index: number) => {
                                  return  ( 
                                    <li>{truncate(condition.reason)}</li>
                                  ); 
                                })
                              ) : (
                                flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )
                              )
                            )}
                          </Typography>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}