import React, { useState, useEffect, ChangeEvent } from 'react';
import { Input, Select, Radio, Typography } from '@material-tailwind/react';
import { useLoading } from '../../ui-components/loadbar/loadingcontext';
import { Check } from "iconoir-react";

const SearchableDropdown = ({setFormData}:any) => {
  const [data, setData] = useState([""]);
  const [error, setError] = useState("");
  // const [loading, setLoading] = useState(true);
  const { setLoading } = useLoading();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        const token = localStorage.getItem('authToken');

        if (!token) {
          console.error('Kein Token gefunden, bitte einloggen');
          return;
        }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/provider-list/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
        });
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
    } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching the data. Please try again later.');
    } finally {
      setLoading(false);
    }
    };

    fetchData();
    // const intervalId = setInterval(fetchData, 10000); // Setze das Intervall auf 5000ms (5 Sekunden)

    // return () => clearInterval(intervalId); // Bereinige das Intervall beim Unmount
}, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
    setFormData((prevData:any) => ({ ...prevData, provider: selectedValue }));
  };

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = data.filter(item =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div >
      <Input className="w-full" id="search">
        <Input.Field
            placeholder="Filter"
            type="text"
            id="provider"
            value={searchTerm}
            onChange={handleSearch}
            className="mb-4"
        />
      </Input>
      <Radio className="hover:transition-all duration-700 ease-in-out hover:border-primary gap-4 p-4 min-h-8 max-h-24 overflow-y-scroll rounded-lg border-surface border-[1px]">
      {filteredData.map((item) => (
        <div className="flex gap-2">
            <Radio.Item 
              id={item} 
              value={item}
              onChange={handleSelectChange} 
              className="rounded"
              required
              checked={selectedItem === item}
            >
                <Radio.Indicator>
                    <Check className="h-4 w-4 stroke-2" />
                </Radio.Indicator>
            </Radio.Item>
            <label htmlFor={item} className="">
            <Typography className="block text-sm font-semibold">
                {item}
            </Typography>
            </label>
        </div>
      ))}
      </Radio>
    </div>
  );
};

export default SearchableDropdown;