import React, { useState, useEffect } from 'react';
import { useLoading } from '../ui-components/loadbar/loadingcontext';
import Pagination from '@mui/material/Pagination';
import {
  Button,
  Input,
  Card
} from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import { 
  Search, 
  PlusCircle,
  ArrowLeftCircleSolid
} from "iconoir-react";
import JsonEditor from "../ui-components/editor/monaco";
import useFetch from '../ui-components/fetch/fetch.js'
import { toast } from 'react-toastify';

const ApiFetcher = () => {
  const [path, setPath] = useState('/api/v1/');
  // const [data, setData] = useState(null);
  // const [error, setError] = useState(null);
  // const { setLoading } = useLoading();
  const reload= 30000;
  const base_url = `${process.env.REACT_APP_API_URL}${path}`;
  const [ url, setUrl ] = useState(base_url);
  const [ globalFilter, setGlobalFilter ] = useState("");
  const [ pages, setPages ] = useState(1);
  const [ page, setPage ] = useState(1)
  const [ searchfilter, setSearchFilter ] = useState("");
  const [ data, data_root ] = useFetch({url: url, reloadInterval: reload});

  useEffect(() => { 
    const entries = (data_root)?.count ?? 0;
    setPages(Math.ceil(entries / 100));
  }, [data_root])

  const handleChange = (event, value) => {
    setPage(value);
    setUrl(`${base_url}?page=${value}&name=${searchfilter}`);
  };

  // const updatePathFromUrl = () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const urlPath = urlParams.get('path') || '';
  //   setPath(urlPath);
  //   fetchData()
  // };

  // useEffect(() => {
  //   // Initialer Ladevorgang, um den URL-Parameter `path` zu lesen
  //   updatePathFromUrl();

  //   // Event Listener hinzufügen, um auf Popstate-Events zu reagieren
  //   window.addEventListener('popstate', updatePathFromUrl);

  //   // Entfernen des Event Listeners bei der Demontage
  //   return () => {
  //     window.removeEventListener('popstate', updatePathFromUrl);
  //   };
  // }, []);


  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     setUrl(`${process.env.REACT_APP_API_URL}${path}`);
  //   }
  // }

  const handleInputChange = (e) => {
    const new_path = e.target.value
    setPath(new_path);
    setUrl(`${process.env.REACT_APP_API_URL}${new_path}`);
  };

  const deleteLastDirInUrl = () => {
    const new_path = path.substring(0, path.lastIndexOf('/'))
    setPath(new_path);
    setUrl(`${process.env.REACT_APP_API_URL}${new_path}`);
  };


  const handleUrlClick = (url) => {
    const new_path = url.replace("http://app.ayedo.cloud","")
    setPath(new_path);
    setUrl(`${process.env.REACT_APP_API_URL}${new_path}`);
  };


  // const handleSearchFilter = (value: string) => {
  //   console.log(value)
  //   setSearchFilter(value);
  //   if (value.length > 3 ||value.length == 0)
  //     setUrl(`${base_url}?name=${value}`);
  // };

  // useEffect(() => {
  //   if (path) {
  //     fetchData();
  //   }
  // }, [path]);

  // const token = localStorage.getItem('authToken');
  // if (!token) {
  //   console.error('Kein Token gefunden, bitte einloggen');
  //   return;
  // }
  if (!path.startsWith("/api/v1/")) {
    setPath("/api/v1/")
  }
  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const newUrl = `${window.location.pathname}?path=${path}`;
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Token ${token}`
  //       },
  //     });
  //     const jsonData = await response.json();
  //     setData(jsonData);
  //   } catch (error) {
  //     //toast.error(`Fehler beim Senden der Daten.${error}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="w-full px-2">
      <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
        <div className="flex w-full shrink-0 gap-2">
          <NavLink onClick={deleteLastDirInUrl} value={path}>
            <Button variant="solid">
              <ArrowLeftCircleSolid className="me-2 h-4 w-4 stroke-2" />
              Back
            </Button>
          </NavLink>
          <div className="w-[70%]">
            <Input>
              <Input.Field
                autoFocus
                placeholder="Search"
                value={path}
                onChange={handleInputChange}
                //onKeyDown={handleKeyDown}
              />
              <Input.Icon placement="end">
                <Search className="h-5 w-5" />
              </Input.Icon>
            </Input>
          </div>
        </div>
      </div>
      
      <div className="w-full overflow-hidden rounded-lg border border-primary/20 overflow-x-scroll">
          {data && (
          <>
          <Pagination 
            count={pages} 
            className="bg-primary" 
            onChange={handleChange}
          />
          <table className="w-full text-left">
            <thead className="border-b border-primary bg-surface-light text-sm font-medium text-foreground dark:bg-primary/10">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="transition ease-in-out duration-300 hover:bg-surface-light hover:dark:bg-primary/10">
                  <td className="p-4 border-b border-surface-light">{item.id}</td>
                  <td className="p-4 border-b border-surface-light">{item.name}</td>
                  <td className="p-4 border-b border-surface-light">{item.description}</td>
                  <td className="p-4 border-b border-surface-light">{
                    <div onClick={() => handleUrlClick(item.url)}>
                      {item.url}
                    </div>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </>
        )}
      </div>
      <div className="w-full overflow-hidden rounded-lg">
          <Card className="m-1 space-y-4">
          <JsonEditor jsonData={data_root} />
          </Card>
      </div>
    </div>
  );
};

export default ApiFetcher;